import { action, computed } from "mobx";
import BaseStore from "../base";
import http from "@app/lib/http";
import report from "../report";

/**
 * State management controlling upload and search steps
 */
export class ImportSearchStore extends BaseStore {
    /**
     * Observable store data
     */
    observable() {
        return {
            currentStep: 0,
            loading: false,
            file: null,
            searches: [],
            l1Reasons: [],
            l2Reasons: [],
            steps: [
                {
                    title: "Upload File",
                    status: "wait",
                },
                {
                    title: "Search Summary",
                    status: "wait",
                },
                {
                    title: "L1 Reasons",
                    status: "wait",
                },
                {
                    title: "L2 Reasons",
                    status: "wait",
                },
                {
                    title: "Review",
                    status: "wait",
                },
            ],
            errors: {
                file: null,
            },
        };
    }

    @action
    setFile(file) {
        this.file = file;
        this.errors = {};
    }

    /**
     * Reset the store to its initial state
     */
    @action
    reset() {
        this.currentStep = 0;
        this.loading = false;
        this.file = null;
        this.searches = [];
        this.l1Reasons = [];
        this.l2Reasons = [];
        this.errors = {
            file: null,
        };
        this.steps = [
            {
                title: "Upload File",
                status: "wait",
            },
            {
                title: "Search Summary",
                status: "wait",
            },
            {
                title: "L1 Reasons",
                status: "wait",
            },
            {
                title: "L2 Reasons",
                status: "wait",
            },
            {
                title: "Review",
                status: "wait",
            },
        ];
    }

    /**
     * Update l2 reasons for the current search type
     */
    @action
    updateL2Reasons = () => {
        this.l2Reasons = this.searches.reduce((acc, search) => {
            const existing = acc.findIndex((s) => s.type === search.type);

            if (existing > -1) {
                search.l2Reasons.forEach((reason) => {
                    const existingReason = acc[existing].reasons.find(
                        (r) => r.name === reason.name,
                    );
                    if (existingReason) {
                        existingReason.count += reason.count;
                    } else {
                        acc[existing].reasons.push({
                            name: reason.name,
                            count: reason.count,
                        });
                    }
                });

                acc[existing].l2Included += search.l2Included;
                acc[existing].l2Excluded += search.l2Excluded;
                acc[existing].count += search.l2Included + search.l2Excluded;
            } else {
                acc.push({
                    type: search.type,
                    reasons: search.l2Reasons.reduce((acc, reason) => {
                        const existing = acc.find((r) => r.name === reason.name);
                        if (existing) {
                            existing.count += reason.count;
                        } else {
                            acc.push({
                                name: reason.name,
                                count: reason.count,
                            });
                        }
                        return acc;
                    }, []),

                    l2Included: search.l2Included,
                    l2Excluded: search.l2Excluded,
                    count: search.l2Included + search.l2Excluded,
                });
            }
            return acc;
        }, []);
    };

    /**
     * Set l2 reason action
     * @param {string} type
     * @param {string} action
     */
    @action
    setL2ReasonAction = (type, reason, action) => {
        this.l2Reasons = this.l2Reasons.map((search) => {
            if (search.type === type) {
                search.reasons = search.reasons.map((r) => {
                    if (r.name === reason) {
                        r.action = action;
                    }
                    return r;
                });
            }
            return search;
        });
    };

    /**
     * Update l1 reasons for the current search type
     */
    @action
    updateL1Reasons = () => {
        this.l1Reasons = this.searches.reduce((acc, search) => {
            const existing = acc.findIndex((s) => s.type === search.type);

            if (existing > -1) {
                search.l1Reasons.forEach((reason) => {
                    const existingReason = acc[existing].reasons.find(
                        (r) => r.name === reason.name,
                    );
                    if (existingReason) {
                        existingReason.count += reason.count;
                    } else {
                        acc[existing].reasons.push({
                            name: reason.name,
                            count: reason.count,
                        });
                    }
                });

                acc[existing].l1Included += search.l1Included;
                acc[existing].l1Excluded += search.l1Excluded;
                acc[existing].count += search.l1Included + search.l1Excluded;
            } else {
                acc.push({
                    type: search.type,
                    reasons: search.l1Reasons.reduce((acc, reason) => {
                        const existing = acc.find((r) => r.name === reason.name);
                        if (existing) {
                            existing.count += reason.count;
                        } else {
                            acc.push({
                                name: reason.name,
                                count: reason.count,
                            });
                        }
                        return acc;
                    }, []),

                    l1Included: search.l1Included,
                    l1Excluded: search.l1Excluded,
                    count: search.l1Included + search.l1Excluded,
                });
            }
            return acc;
        }, []);
    };

    /**
     * Set l1 reason action
     * @param {string} type
     * @param {string} reason
     * @param {string} action
     */

    @action
    setL1ReasonAction = (type, reason, action) => {
        this.l1Reasons = this.l1Reasons.map((search) => {
            if (search.type === type) {
                search.reasons = search.reasons.map((r) => {
                    if (r.name === reason) {
                        r.action = action;
                    }
                    return r;
                });
            }
            return search;
        });
    };

    /**
     * Return the project id if the currently loaded project
     */
    @computed get project() {
        return report.id;
    }

    setError({ code, message, section }) {
        const map = {
            INVALID_FILE_FORMAT: "Please upload a valid file to continue.",
            INVALID_FILE_STRUCTURE:
                "Required columns with exclusion information are missing in the file.",
            IMPORT_FAILED: "An error occurred while importing the file. Please try again.",
            MISSING_ANSWER_GRADE: message,
            DEFAULT_MESSAGE: "An error occurred while processing the file. Please try again.",
        };

        let error = map[code] || map.DEFAULT_MESSAGE;

        if (error) {
            this.errors[section] = error;
        }
    }

    /**
     * Load the selected file searches
     */
    @action
    async loadSearchesFromFile() {
        try {
            this.loading = true;
            const { data } = await http.post(`/project/${this.project}/search/import`, {
                file: this.file,
            });
            this.searches = data;
            this.loading = false;
            return true;
        } catch (ex) {
            this.loading = false;
            this.setError({
                code: ex.response?.data.code,
                message: ex.response?.data.error,
                section: "file",
            });
            return false;
        }
    }

    /**
     * Save search results
     */
    @action
    async saveSearchResults() {
        try {
            this.loading = true;
            await http.post(`/project/${this.project}/search/import/save`, {
                searches: this.searches,
                l1Reasons: this.l1Reasons,
                l2Reasons: this.l2Reasons,
                file: this.file,
            });
            this.loading = false;
            return true;
        } catch (ex) {
            this.loading = false;
            this.setError({
                code: ex.response?.data.code,
                message: ex.response?.data.error,
                section: "review",
            });
            return false;
        }
    }
}

export default new ImportSearchStore();
