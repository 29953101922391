import moment from "moment";
import config from "@app/config";
import { Search, CommentStatusType, ReferenceStyle } from "@app/constants";
import { SearchType, ArticleReviewState, ProjectStatus } from "@app/constants";
import xss from "xss";

const MIN_AUTHOR_GROUP_SIZE = 6;
const MAX_AUTHORS_PER_GROUP = 3;

const ROLE_LABELS = {};
config.roles.map((role) => {
    ROLE_LABELS[role.value] = role.label;
});

const PROJECT_ROLE_LABELS = {};
config.projectRoles.map((role) => {
    PROJECT_ROLE_LABELS[role.value] = role.label;
});

const exports = {};

/**
 * Return formatted date
 */
exports.date = function date(value, format = "MMM DD, YYYY") {
    if (!value) {
        return undefined;
    }

    return moment(value).format(format);
};

/**
 * Return formatted time
 */
exports.time = function time(value, format = "hh:mm A") {
    if (!value) {
        return undefined;
    }

    return moment(value).format(format);
};

/**
 * Return formatted  date + time
 */
exports.datetime = function datetime(value, format = "MMM DD, YYYY @ hh:mm A") {
    if (!value) {
        return undefined;
    }

    return moment(value).format(format);
};

/**
 * Return time passed since the moment in a human readable format
 */
exports.elapsed = function elapsed(value) {
    if (!value) {
        return undefined;
    }

    let now = moment();
    let duration = moment.duration(now.diff(value));

    return duration.humanize();
};

/**
 * Format a number by adding , in the thousands
 */
exports.number = function number(value) {
    if (value === undefined || value === null || value === "") {
        return undefined;
    }

    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Pluralize a string
 */
exports.pluralize = function pluralize(value, string, plural) {
    if (value === undefined || value === null) {
        return string;
    }

    if (value > 1 || value === 0) {
        if (plural) {
            return plural;
        }

        return `${string}s`;
    }

    return string;
};

/**
 * Return role labels by role names
 */
exports.role = function role(value) {
    return ROLE_LABELS[value];
};

/**
 * Return Project role labels by role names
 */
exports.projectRoles = function role(value) {
    return PROJECT_ROLE_LABELS[value];
};

/**
 * Format a file path
 */
exports.file = function file(value) {
    if (!value) {
        return value;
    }

    value = String(value);
    if (value.match(/https?:\/\//)) {
        return value;
    }

    if (value.startsWith("data:")) {
        return value;
    }

    if (value.startsWith("/")) {
        return value;
    }

    return `/api/file/${value}`;
};

/**
 * Format a file path
 */
exports.image = function image(value, options = {}) {
    if (!value) {
        return value;
    }

    value = String(value);
    if (value.match(/https?:\/\//)) {
        return value;
    }

    if (value.startsWith("data:")) {
        return value;
    }

    if (value.startsWith("/")) {
        return value;
    }

    let url = `/api/image/${value}`;
    if (options.width || options.height) {
        url += `/${options.width || "*"}/${options.height || "*"}`;
    }

    return url;
};

/**
 * Replace new lines with html breaks
 */
exports.nl2br = function nl2br(value) {
    if (!value) {
        return value;
    }

    value = String(value);
    value = value.replace(/\n/g, "<br/>");
    return value;
};

/**
 * Translate search engines to their names
 */
exports.searchDb = function searchDb(value) {
    if (!value) {
        return undefined;
    }

    switch (value) {
        case Search.PUBMED:
            return "PubMed";
        case Search.EUPMC:
            return "Europe PMC";
        case Search.GOOGLE:
            return "Google Scholar";
        case Search.EMBASE:
            return "Embase";
        case Search.COCHRANE:
            return "Cochrane";
        case Search.PROSPERO:
            return "Prospero";
        case Search.OTHER:
            return "Other";
        default:
            return value;
    }
};

/**
 * Shorten a string to a specified size without cutting words in half
 */
exports.shorten = function shorten(text, limit = 50) {
    if (!text) {
        return text;
    }

    if (text.length <= limit) {
        return text;
    }

    let short = text.substr(0, limit);
    let lastSpace = short.lastIndexOf(" ");
    return short.substr(0, lastSpace) + " ...";
};

/**
 * Create a list of authors. We group the authors and show only the MAX_AUTHORS_PER_GROUP et al.
 * If the number of authors is less that the threshold to group them show every author.
 */
exports.authors = function authors(
    authors,
    { limit = MAX_AUTHORS_PER_GROUP, min = MIN_AUTHOR_GROUP_SIZE },
    style,
) {
    // if the number of authors is less that the minimal group size
    // do not group the authors and show all of them
    if (limit && authors.length <= min) {
        limit = 0;
    }

    const included = limit ? authors.slice(0, limit) : authors;
    let names;
    const firstName = (author) =>
        (author?.firstName ?? "")
            .trim()
            .replace(/\s+/g, " ")
            .split(/\s+/)
            .map((part) => part[0]?.toUpperCase() ?? "")
            .join("");

    names = authors
        ?.map((author) => {
            const initials = firstName(author);
            return `${author?.lastName} ${initials}`;
        })
        ?.join(", ");

    if (authors.length > included.length) {
        names += " et al.";
    }

    return names;
};

const formatAuthorsChicagoStyle = (authors) => {
    if (!authors || authors.length === 0) {
        return "";
    }
    const authorCount = authors.length;

    if (authorCount === 1) {
        const author = authors[0];
        return `${author.lastName}, ${author.firstName}.`;
    }
    if (authorCount === 2) {
        const author1 = authors[0];
        const author2 = authors[1];
        return `${author1.lastName}, ${author1.firstName}, and ${author2.firstName} ${author2.lastName}.`;
    }
    if (authorCount >= 3 && authorCount <= 6) {
        const authorList = authors
            .slice(1, 3)
            .map((author) => `${author.firstName} ${author.lastName}`)
            .join(", ");
        const firstAuthor = authors[0];
        const nextAuthor = authors[authors.length - 1];
        return `${firstAuthor.lastName}, ${firstAuthor.firstName}, ${authorList}, and ${nextAuthor.firstName} ${nextAuthor.lastName}.`;
    }
    if (authorCount > 6) {
        const authorList = authors
            .slice(1, 3)
            .map((author) => `${author.firstName} ${author.lastName}`)
            .join(", ");
        const firstAuthor = authors[0];
        return `${firstAuthor.lastName}, ${firstAuthor.firstName}, ${authorList}, et al.`;
    }

    return "";
};

const formatChicagoStyle = (reference) => {
    const parts = [];

    if (reference.authors.length > 0) {
        parts.push(formatAuthorsChicagoStyle(reference.authors));
    }

    const {
        journalName,
        journalVolume,
        journalIssue,
        pageNumbers,
        link,
        ids,
        publicationYear,
        doi,
    } = reference;

    if (publicationYear) {
        parts.push(`${publicationYear}.`);
    }

    const title = (reference.title ?? "").replace(/\.\s*$/, "");
    parts.push(`"${title}."`);

    if (journalName) {
        parts.push(`<i class="italic">${journalName}</i>`);
    }

    if (journalVolume) {
        parts.push(`${journalVolume}`);
    }

    if (journalIssue) {
        parts.push(`(${journalIssue})${pageNumbers ? ":" : "."}`);
    }

    if (pageNumbers) {
        parts.push(`${pageNumbers}.`);
    }

    if (doi || ids?.doi) {
        parts.push(`https://doi.org/${doi || ids.doi}.`);
    } else if (link) {
        parts.push(`${link}.`);
    }

    return parts.join(" ");
};

exports.properCase = function properCase(text) {
    const lowerText = text.toLowerCase();
    return lowerText.charAt(0).toUpperCase() + lowerText.slice(1, lowerText.length);
};

/**
 * Sanitize html
 */
const sanitize = (exports.sanitize = function sanitize(text, extra = {}) {
    if (!text) {
        return text;
    }

    return xss(text, {
        whiteList: {
            i: ["class"],
            b: ["class"],
            strong: ["class"],
            em: ["class"],
            u: [],
            sup: [],
            sub: [],
            ...extra,
        },
    });
});

exports.xss = function (text, whitelist) {
    if (!text) {
        return text;
    }

    return xss(text, {
        whiteList: whitelist ?? {},
    });
};

/**
 * Escape a string to be used in regexp
 */
exports.escapeRegExp = function role(text) {
    return text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

/**
 * Search and highlight a keyword
 */
function highlightWord(text, word, { positive }) {
    const reg = new RegExp(`(${exports.escapeRegExp(word)})`, "gi");
    const replace = positive ? "<em class='pos'>$1</em>" : "<em class='neg'>$1</em>";
    return text.replaceAll(reg, replace);
}

/**
 * Highlight a a list of keywords in a text
 */
exports.highlight = function (text, keywords = [], { sanitize = false } = {}) {
    if (sanitize) {
        text = exports.sanitize(text);
    }

    // apply the highlight
    for (const entry of keywords) {
        text = highlightWord(text, entry.label, { positive: entry.positive });
    }

    return text;
};

/**
 * Convert a text to appropriate file name by removing the special symbols,
 * replacing the space with _ and shortening the length of the name
 */
exports.toFileName = function (text) {
    if (!text) {
        return text;
    }

    // replace spaces with underscore
    text = text.replace(/\s+/g, "_");

    // remove all spacial symbols
    text = text.replace(/\W/g, "");

    // convert it to lowercase
    text = text.toLowerCase();

    // get the first 50 symbols only
    text = text.substr(0, 50);

    return text;
};

/**
 * Format the project article id
 */
exports.cid = function (id) {
    return `C${id}`;
};

/**
 * Converts a date to utc
 */
exports.toUTC = function (date) {
    date = new Date(date);
    date.setTime(date.getTime() - date.getTimezoneOffset() * 60000);
    return date;
};

/**
 * Extract the initials from a user name
 */
exports.initials = function (name) {
    let initials = [];
    const names = name?.split(/\s+/);
    const first = names?.length > 0 ? names[0] : undefined;
    const last = names?.length > 1 ? names[names.length - 1] : undefined;

    if (first) {
        initials.push(first.charAt(0));
    }

    if (last) {
        initials.push(last.charAt(0));
    }

    return initials.join("").toUpperCase();
};

exports.searchType = (type) => (type === SearchType.SOTA ? "SoTA" : "DUE");

exports.articleReviewState = (input) => {
    if (!input) {
        return input;
    }

    return ArticleReviewState[input] ?? input;
};

exports.mentions = (comment) => {
    if (!comment) {
        return comment;
    }

    // Convert mentions if any to readable format.
    // markup format: "@{{full name||id}}"

    const regex = /@{{(.*?)\|\|([a-f0-9]{24})}}/g;
    return comment.replace(regex, function (_, $1) {
        return `<b>` + $1 + `</b>`;
    });
};

exports.projectStatus = (status) => {
    return status === ProjectStatus.HOLD ? "On Hold" : exports.properCase(status);
};

exports.publicationSource = (publication = {}, style) => {
    // Return custom citation if set
    if (publication.customCitation) {
        return sanitize(publication.customCitation);
    }

    // verify that all needed pieces exist
    if (!publication.journalName) {
        return undefined;
    }

    if (style === ReferenceStyle.AMA) {
        return amaPublicationSource(publication);
    } else {
        return vancouverPublicationSource(publication);
    }
};

function vancouverPublicationSource(publication) {
    // start with the journal name
    let result = `${sanitize(publication.abbrJournalName || publication.journalName)}.`;

    // add the publication date
    if (publication.publicationYear) {
        let date = publication.publicationYear;

        if (publication.publicationMonth) {
            // Moment uses a 0-index value for setting the month
            let month = moment()
                .month(publication.publicationMonth - 1)
                .format("MMM");

            date += ` ${month}`;
        }

        result += ` ${date}`;
    }

    // add the journal issue
    if (publication.journalVolume) {
        let volume = publication.journalVolume;
        if (publication.journalIssue) {
            volume += `(${sanitize(publication.journalIssue)})`;
        }

        result += `;${sanitize(volume)}`;
    }

    // add the page numbers
    if (publication.pageNumbers) {
        result += `:${sanitize(publication.pageNumbers)}`;
    }

    // at the final dot
    if (!result.endsWith(".")) {
        result += ".";
    }

    return result;
}

function amaPublicationSource(publication) {
    // start with the journal name
    let result = `<i class="italic">${sanitize(
        publication.abbrJournalName || publication.journalName,
    )}</i>.`;

    // add the publication date
    if (publication.publicationYear) {
        result += ` ${sanitize(publication.publicationYear)}`;
    }

    // add the journal issue
    if (publication.journalVolume) {
        let volume = publication.journalVolume;

        if (publication.journalIssue) {
            volume += `(${publication.journalIssue})`;
        }

        result += `;${sanitize(volume)}`;
    }

    // add the page numbers
    if (publication.pageNumbers) {
        result += `:${sanitize(publication.pageNumbers)}`;
    }

    // at the final dot
    if (!result.endsWith(".")) {
        result += ".";
    }

    const doi = publication.doi ?? publication.ids?.doi;
    if (doi) {
        result += ` doi:${sanitize(doi)}`;
    }

    return result;
}

exports.parseMonth = function (rawMonthValue) {
    if (!rawMonthValue) {
        return undefined;
    }

    const monthNumber = parseInt(rawMonthValue);
    if (Number.isNaN(monthNumber)) {
        return monthNumber;
    }

    return moment()
        .month(monthNumber - 1)
        .format("MMMM");
};

exports.commentActionLabel = (reply) => {
    if (!reply) {
        return reply;
    }

    if (reply.type === CommentStatusType.UNRESOLVE) {
        return "Comment was marked as open";
    } else if (reply.type === CommentStatusType.RESOLVE) {
        return "Comment was marked as resolved";
    } else {
        return reply.text;
    }
};

/**
 * Format a reference
 */
exports.reference = (ref, style) => {
    style = style ?? ReferenceStyle.Vancouver;
    if (style === ReferenceStyle.Chicago) {
        return formatChicagoStyle(ref);
    }

    const parts = [];

    if (ref.authors.length) {
        const limit = style === ReferenceStyle.Chicago ? 10 : 6;
        const min = style === ReferenceStyle.Chicago ? 1 : 6;

        const authors = exports.authors(ref.authors, { limit, min }, style);
        const authorsValue = authors.replace(/\.\s*$/, "");
        parts.push(authorsValue);
    }

    // add the article title
    const title = (ref.title ?? "").replace(/\.\s*$/, "");
    if (style === ReferenceStyle.Chicago) {
        parts.push(`"${sanitize(title)}"`);
    } else {
        parts.push(sanitize(title));
    }

    // add the publication information
    if (ref.journalName) {
        const publicationSource = exports.publicationSource(ref, style);
        parts.push(publicationSource);
    }

    return parts.join(". ");
};

/**
 * Format the channel name
 */
exports.channel = (channel) => {
    return channel === SearchType.SLR ? "DUE" : "SoTA";
};

/**
 * Format caption string
 */
exports.caption = (text) => {
    const div = document.createElement("div");
    div.innerText = text;

    const html = div.innerHTML.replaceAll(/&lt;(\/?(sup|sub))&gt;/g, "<$1>");
    return html;
};

export default exports;
